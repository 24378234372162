<template>
	<div> 
	<v-card>		
		<s-crud
			title="Guías de remisión"
			:config="config"
			:filter="filter"
			add
			edit
			@edit="openEditDialogReferralGuide()"
			remove
			add-no-function
			@add="openDialogReferral()" 
			ref="crudguideremision"
			@rowSelected="rowSelected($event)"
			sync
			pdf
			@pdf="downloadGuide()"
			@sync="syncDoc()"
			search-input
			print 
			@print="openFile()"
		>
			<template v-slot:options>
				<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								text
								black 
								@click="lowGuide()"
								small>
								<v-icon style="font-size: 16px !important">mdi-cancel</v-icon>
							</v-btn>
						</template>
						<span>Dar Baja{{lengthSelected}}</span>
					</v-tooltip>

			</template>

			<template v-slot:filter>
				<v-container>
					<v-row>
						<!--  -->
						<v-col  v-if="$fun.isAdmin()">
							<s-select-area v-model="filter.AreID" label="Área" />
						</v-col>
						<!-- <v-col cols="2" class="pt-5 pl-5 pb-5" >
							<s-select-definition                  
								:def="1409"                                       
								label="Area de Guia"                 
								clearable
								v-model="filter.TypeAreGuide"
								readonly
								hidden
								></s-select-definition>
						</v-col> -->
						<v-col>
							<s-date
								label="Fecha Inicio"
								v-model="filter.DateBegin"
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin"
								v-model="filter.DateEnd"
							></s-date>
						</v-col>
						<v-col>
							<s-select-definition
								label="Tipo Guía"
								:def="1411"
								v-model="filter.TypeGuide"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			
			<template v-slot:Generate = "{ row }">
				<v-btn x-small color="blue"  @click="generateXml(row)" :disabled="row.RegState == 1 || row.RegState == 2 || row.RegState == 3 || row.RegState == 5 || row.RegState == 7"> <!-- :disabled="row.RegState != 2" -->
					<v-icon style="font-size: 16px !important">mdi-note-outline</v-icon>
				</v-btn>
				 
			</template>
			<template v-slot:View = "{ row }">
				 
				<!-- <v-btn x-small color="success" @click="showPdf(row)">
					<v-icon style="font-size: 16px !important">far fa-eye</v-icon>
				</v-btn> -->
				<v-btn 
					x-small 
					color="primary" 
					@click="updateStateFacele(row)"
					v-if="row.RegState == 1 || row.RegState == 2"
				>
					<v-icon style="font-size: 16px !important">mdi-reload</v-icon>
				</v-btn>
				<!----> 
			</template>
			<template v-slot:RegState ="{ row }">
				<v-chip 
					:color="row.RegStateColor"
					x-small
				>
					{{row.RegStateName}}
				</v-chip>
			</template>

		</s-crud>

		<!-- Guía de remisión remitente -->
		<v-dialog
			v-if="dialogReferral"
			v-model="dialogReferral"
			fullscreen
			persistent
		>
			<lgs-referral-guide-create
				v-if="!isEdit"
				@closeDialogReferral="closeDialogReferral()"
				@refreshTable="refreshTable()"
			></lgs-referral-guide-create>
			<lgs-referral-guide-edit
				v-else
				@closeDialogReferral="closeDialogReferral()"
				:referralGuideSelected="referralGuideSelected"
				:typeGuide="1"
			></lgs-referral-guide-edit>
		</v-dialog>
		<v-dialog v-if="dialogGuidePdf" v-model="dialogGuidePdf" fullscreen
			persistent>
			<guide-pdf :referralGuideSelected="referralGuideSelected" @close="dialogGuidePdf = false"></guide-pdf>
		</v-dialog>
	</v-card>	
	</div>
</template>

<script>
	import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
	import LgsReferralGuideCreate from './LgsReferralGuideCreate.vue';
	import LgsReferralGuideEdit from './LgsReferralGuideEdit.vue';
	import GuidePdf from './LgsPrintGuidePdf.vue';  

	export default {
		components: { 
			LgsReferralGuideCreate,
			LgsReferralGuideEdit,
			GuidePdf
		},

		data() {
			return {
				lengthSelected : 0,
				dialogReferral : false,
				typeReferralGuide : 0,
				isEdit : false,
				filter: {
					TypeAreGuide: "",
					UsrID : this.$fun.getUserID(),
					TypeGuide: 0,
				},
				config: { 
					model : {
						RegID : "ID",
						View: "",
						Generate: "",
						RegState: "",
						RespFacele : "",
						RegDate : "date"
					},
					service : _sReferral,
					headers : [
						{text : '', value : 'Generate'},
						{text : '', value : 'View'},
						{text : 'ID', value : 'RegID'} ,
						{text : 'Serie', value : 'RegSerie'} ,
						{text : 'Correlativo', value : 'RegNumber'} ,
						{text : 'Fecha Emision', value : 'RegDate'} , 
						{text : 'Area', value : 'TypeAreGuideName'} , 
						{text : 'Nombre de destinatario', value : 'RegBusinessNameDestination'} ,
						{text : 'Documento de destinatario', value : 'RegDocumentNumberDestination' } ,
						{text : 'Peso', value : 'RegGrossWeightCargo' } ,
						{text : 'Motivo', value : 'TypeTransferReasonCodeName'} ,
						// {text : 'Motivo', value : 'TypeTransferReasonName'} ,
						{text : 'Transporte', value : 'RegBusinessNameTransport'},
						{text : 'Observación Fac.', value : 'RespFacele'},
						{text : 'Estado', value : 'RegState'},

					]
				},
				referralGuideSelected : {},
				dialogGuidePdf: false,
				pathPdfFacele: "",
			}
		},

		methods: {
			openFile() {
				_sReferral
					.documentPdf(this.referralGuideSelected, this.$fun.getUserID())
					.then(r => {
						this.$fun.download(r.data, this.referralGuideSelected.RegSerie + "-" + this.referralGuideSelected.RegNumber + ".pdf");
					});
				// _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
				// 	this.$fun.download(r.data, url.name);
				// });
			},
			refreshTable()
			{
				this.$refs.crudguideremision.refresh();
			},
			syncDoc(){
				_sReferral
						.refresh( this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Sincronizado correctamente", "success");
								 
							}
						})
			},
			lowGuide()
			{
				if(this.referralGuideSelected.RegID == undefined)
				{
					this.$fun.alert("Selecciona una guía", "warning");
					return;
				}
				this.$fun.alert("¿Seguro de dar de baja?", "question")
				.then(r => {
					if(r.value)
					{
						this.referralGuideSelected.RegState = 3;
						this.referralGuideSelected.UsrUpdateID = this.$fun.getUserID();
						_sReferral
						.save(this.referralGuideSelected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guía dado de baja correctamente", "success");
								this.$refs.crudguideremision.refresh();
							}
						})
					}
				})
			},

			updateStateFacele(item)
			{
				if(item.RegState == 2)
				{
					_sReferral
					.updatestatesunat(item, this.$fun.getUserID(), item.RegNumber)
					.then(resp => {
						if(resp.status == 200)
						{
							this.$refs.crudguideremision.refresh();
						}
					});
				}
				else{
					_sReferral
					.updatestatefacele(item, this.$fun.getUserID(), item.RegNumber)
					.then(resp => {
						if(resp.status == 200)
						{
							this.$refs.crudguideremision.refresh();
						}
					})
				}
				
			},

			rowSelected(referralGuide)
			{
				console.log('referralGuide ', referralGuide);
				if(referralGuide.length > 0)
				{
					this.lengthSelected = referralGuide.length;
					this.referralGuideSelected = referralGuide[0];
					this.referralGuideSelected.TypeGuide = 1;


					console.log( "this.referralGuideSelected", this.referralGuideSelected )
					


					_sReferral
					.detail(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.referralGuideItems = resp.data;
						}
					});

					_sReferral
					.relateddocuments(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.itemsRelatedDocuments = resp.data;
						}
					});

					_sReferral
					.vehicles(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							console.log('vehiculos', resp.data);
							this.referralGuideSelected.LgsReferralGuideVehicles = resp.data;
						}
					});

					_sReferral
					.drivers(this.referralGuideSelected, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200)
						{
							this.referralGuideSelected.LgsReferralGuideDrivers = resp.data;
						}
					});
					
					//armamos la serie
					let objSerie = {
						DedDescription : this.referralGuideSelected.RegSerie,
						DedValue : this.referralGuideSelected.RegSerieID,
					}
					this.referralGuideSelected.objSerie = objSerie;
					this.referralGuideSelected.DtrIDStartPointOriginal = this.referralGuideSelected.DtrIDStartPoint;
				}
			},
			downloadGuide(){
				if(this.referralGuideSelected.RegID != undefined)
				{
					this.dialogGuidePdf = true;					
				}				
			},
			openDialogReferral() {
				this.dialogReferral = true;
			},

			openEditDialogReferralGuide()
			{
				this.isEdit = true;
				this.dialogReferral = true;
			},

			closeDialogReferral()
			{
				this.$refs.crudguideremision.refresh();
				this.dialogReferral = false;
				this.isEdit = false;
				
			},

			generateXml(guide){

				console.log("genreate", guide)
				this.$fun.alert("¿Seguro de generar?", "question")
				.then(r => {
					if(r.value)
					{
						_sReferral.generateXml(guide, this.$fun.getUserID()).then((r) => {

							if (r.status == 200) {
								this.$refs.crudguideremision.refresh();
							}
						});
					}
				})

				
			},

			showPdf(guide){ 
				console.log("showPdf", guide) 
				_sReferral.showPdf(guide, this.$fun.getUserID()).then((r) => {
					
					if (r.status == 200) {
						console.log('ressss', r.data);
						this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Guia Remision");
					}
				})
			},

		},
		mounted(){
			let permis = 0;
			if (this.$fun.hasSpecialPermission("LGS")) {
				permis = 1
			}
			if (this.$fun.hasSpecialPermission("AT")) {
				permis = 2
			}
			if (this.$fun.hasSpecialPermission("COMEX")) {
				permis = 3
			}

			this.filter.TypeAreGuide = permis;
		},
		created(){

			console.log("persimtios", this.$fun.getSecurity())

			// if (!this.$fun.isAdmin()) this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;
			var areAlow = this.$fun.getSecurity().AreasAllow
			if (areAlow.length > 0) {
				
				if (!this.$fun.isAdmin()) this.filter.AreID = areAlow[0].AreID;
			}
		}
	}
</script>
