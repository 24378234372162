<template>
	<v-card>
		<s-select-definition
			:def="1403"
			@getDataInitial="getDataReasonTransfer($event)"
			hidden
		></s-select-definition>
		<s-select-definition
			:def="1406"
			@getDataInitial="getDataTypeArrivalPoint($event)"
			hidden
		></s-select-definition>
		<s-select-definition
			:def="1407"
			@getDataInitial="getDataTypeTransport($event)"
			hidden
		></s-select-definition>

		<s-toolbar
			label="Guía de remisión"
			save
			@save="save()"
			close
			@close="closeDialogReferral()"
			color="#E0F7F2"
		></s-toolbar>
		<v-col>
			<!-- destinatario y detalles -->
			<v-row>
				<v-col lg="3">
					<v-card dense outlined style="padding-bottom : 10px">
						<v-card-title style="margin-top : -10px">
							<h5>Destinatario</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col  class="s-col-form">
									<s-select-definition
										v-model="objSerie"
										:dgr="groupGuide"
										return-object
										label="Serie"
										:def="1413"
									></s-select-definition>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form">
									<s-select-definition
										label="Tipo Documento"
										:def="1036"
										v-model="referralGuide.TypeDocumentDestination"
									></s-select-definition>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form">
									<s-text
										label="N° Doc."
										number
										@keyupEnter="searchSunat($event, 1)"
										v-model="referralGuide.RegDocumentNumberDestination"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form">
									<s-text
										label="Nombre/Razón Social"
										v-model="supplierDestination.SunName"
										 
									></s-text>
									<v-label style="color : red" class="mt-2">
										<h5>{{ supplierDestination.SunState }}</h5>
									</v-label>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form">
									<s-text
										label="Punto Llegada"
										v-model="supplierDestination.SunAddress"
										 
									></s-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col lg="9">
					<v-card dense outlined>
						<v-card-title style="margin-top : -10px">
							<h5>Detalles</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col sm="12" lg="2" cols="2" class="s-col-form">
									<s-select
										label="Tipo"
										:items="itemsTypeDetail"
										v-model="referralGuideItem.TypeDetail"
									></s-select>
								</v-col>
								<v-col cols="" class="s-col-form">
									<s-textarea
										v-if="referralGuideItem.TypeDetail == 2"
										label="Descripción"
										v-model="referralGuideItem.RgdDescription"
									></s-textarea>
									<s-article
										v-if="referralGuideItem.TypeDetail == 1"
										label="Artículo"
										return-object
										@input="inputArticle($event)"
										v-model="referralGuideItem.RgdDescription"
									></s-article>
								</v-col>
								<v-col cols="1" class="s-col-form">
									<v-btn
										color="success"
										fab
										small
										@click="addReferralGuideItems()"
									>
										<v-icon> mdi-plus </v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form">
									<s-select-definition
										label="Unidad"
										:def="1206"
										clearable
										return-object
										v-model="objUnitMeasurement"
									></s-select-definition>
								</v-col>
								<v-col class="s-col-form">
									<s-text
										label="Cantidad"
										decimal
										v-model="referralGuideItem.RgdQuantity"
									></s-text>
								</v-col>
								<v-col class="s-col-form">
									<s-text
										label="Total"
										decimal
										v-model="referralGuideItem.RgdTotal"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-data-table
										dense
										:headers="headerDetailArticle"
										:items="referralGuideItems"
									>
										<template v-slot:item.action="{ item }">
											<v-btn
												color="error"
												x-small
												@click="deleteDetail(item)"
											>
												<v-icon x-small>fas fa-trash-alt</v-icon>
											</v-btn>
											<v-btn
												color="warning"
												x-small
												@click="editItemDetail(item)"
											>
												<v-icon x-small>mdi-pencil</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			
			<!-- datos generales -->
			<v-row>
				<v-col>
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title style="margin-top : -10px">
							<h5>Datos generales de la carga</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col>
									<s-date
										label="Fecha de Emision"
										v-model="referralGuide.RegDate"
									></s-date>
								</v-col>
								<v-col>
									<s-select-definition
										label="Unidad de medida de la carga"
										:def="1206"
										add
										v-model="referralGuide.TypeUnitMeasurement"
									></s-select-definition>
								</v-col>
								<v-col>
									<s-text
										label="Peso Bruto de la carga"
										decimal
										v-model="referralGuide.RegGrossWeightCargo"
									></s-text>
								</v-col>
								<v-col>
									<s-text
										label="Peso Neto de la carga"
										decimal
										v-model="referralGuide.RegNetWeightCargo"
									></s-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			
			<!-- punto de partida y llegada -->
			<v-row>
				<v-col cols="6">
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title style="margin-top : -10px">
							<h5>Punto de llegada</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col class="s-col-form">
									<s-select-departament
										v-model="referralGuide.DptIDArrivalPoint"
										label="Departamento"
										full
									></s-select-departament>
								</v-col>
								<v-col class="s-col-form">
									<s-select-province
										label="Provincia"
										v-model="referralGuide.PrvIDArrivalPoint"
										:DepartmentUbigeo=" referralGuide.DptIDArrivalPoint" 
										full
									></s-select-province>
								</v-col>
								<v-col class="s-col-form">
									<s-select-district
										v-model="referralGuide.DtrIDArrivalPoint"
										:ProvinceUbigeo="referralGuide.DptIDArrivalPoint + '' + referralGuide.PrvIDArrivalPoint"
										label="Distrito"
										full
									></s-select-district>
								</v-col>
							</v-row>
							<v-row>
								<v-col >
									<s-text
										v-model="referralGuide.RegAddressArrivalPoint"
										label="Dirección detallada"
									></s-text>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.TypeTransferReason == 12 || referralGuide.TypeTransferReason == 11 || referralGuide.TypeTransferReason == 6">
									<v-col>
										<s-text
											label="Codigo de establecimiento"
											v-model="referralGuide.RegStablishmentCodeArrival"
											:maxlength="8"
										></s-text>
									</v-col>
								</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title style="margin-top : -10px">
							<h5>Punto de partida</h5>
							<v-spacer vertical></v-spacer>
							<v-radio-group
								v-model="referralGuide.TypeStartPoint"
								row
								hide-details=""
							>
								<v-radio
									v-for="item in itemsStartPoint"
									:label="item.DedDescription"
									:value="item.DedValue"
									:key="item.DedValue"
								></v-radio>
							</v-radio-group>
						</v-card-title>
						<v-card-text>
							<!-- v-if="referralGuide.TypeStartPoint == 2" -->
							<v-row>
								<v-col class="s-col-form">
									<s-select-departament
										v-model="referralGuide.DptIDStartPoint"
										label="Departamento"
										full
									></s-select-departament>
								</v-col>
								<v-col class="s-col-form">
									<s-select-province
										label="Provincia"
										v-model="referralGuide.PrvIDStartPoint"
										:DepartmentUbigeo=" referralGuide.DptIDStartPoint" 
										full
									></s-select-province>
								</v-col>
								<v-col class="s-col-form">
									<s-select-district
										v-model="referralGuide.DtrIDStartPoint"
										
										:ProvinceUbigeo="referralGuide.DptIDStartPoint + '' + referralGuide.PrvIDStartPoint"
										label="Distrito"
										full
									></s-select-district>
								</v-col>
							</v-row>
							<v-row>
								<v-col >
									<s-text
										label="Dirección detallada"
										v-model="referralGuide.RegAddressStartPoint"
									></s-text>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.TypeTransferReason == 6">
									<v-col>
									<s-text
										label="Codigo de establecimiento"
										v-model="referralGuide.RegStablishmentCodeStart"
										:maxlength="8"
									></s-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			
			<!-- datos del transportista y observaciones generales-->
			<v-row>
				<v-col cols="12">
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title style="margin-top : -10px">
							<h5>Datos del transportista</h5>
							<v-spacer vertical></v-spacer>
							<v-radio-group
								v-model="referralGuide.TypeTransport"
								row
								hide-details=""
							>
								<v-radio
									v-for="item in itemsTypeTransport"
									:label="item.DedDescription"
									:value="item.DedValue"
									:key="item.DedValue"
								></v-radio>
							</v-radio-group>
						</v-card-title>
						<v-card-text>
							<v-row v-if="referralGuide.TypeTransport != 0">
								<v-col cols="4" class="s-col-form">
									<s-text
										label="N° Ruc"
										number
										:min="0"
										@keyupEnter="searchSunat($event, 3)"
										v-model="referralGuide.RegRucTransport"
									></s-text>
								</v-col>
								<v-col cols="8" class="s-col-form">
									<s-text
										label="Razón social"
										v-model="referralGuide.RegBusinessNameTransport"
									></s-text>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.TypeTransport != 0">
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Marca Transporte"
										v-model="referralGuide.RegBrandTransport"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Placa Transporte"
										v-model="referralGuide.RegPlateTransport"
										:maxlength="8"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Licencia Conductor"
										v-model="referralGuide.RegDriverLicenceTransport"
										:maxlength="10"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Constancia de inscripción"
										v-model="referralGuide.RegConstancyInscripcionTransport"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-select-definition
										label="Tipo Documento"
										:def="1036"
										add
										v-model="referralGuide.RegTypeDocumentTransport"
									></s-select-definition>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="N° Doc"
										number
										:min="0"
										v-model="referralGuide.RegDocumentNumberTransport"
										@keyupEnter="searchSunat($event, 2)"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Apellidos chofer"
										v-model="referralGuide.RegLastNameDriver"
									></s-text>
								</v-col>
								<v-col cols="6" class="s-col-form">
									<s-text
										label="Nombres - chofer"
										v-model="referralGuide.RegNamesDriver"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card-title>
										<h5>Datos de vehículos</h5>
										<v-spacer></v-spacer>
										<v-btn
											color="primary"
											small
											@click="openDialogVehicle()"
										>
											<v-icon>mdi-plus</v-icon>{{"Agregar"}}
										</v-btn>
									</v-card-title>
									<v-card-text>
										<v-data-table
											:headers="headersVehicle"
											:items="itemsVehicle"
											dense
											hide-default-footer
										>

											<template v-slot:item.action="{item}">
												<v-btn
													color="error"
													x-small
													@click="deleteVehicle(item)"
												>
													<v-icon x-small>mdi-delete</v-icon>
												</v-btn>
											</template>
										</v-data-table>
									</v-card-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card-title>
										<h5>Datos de conductores</h5>
										<v-spacer></v-spacer>
										<v-btn
											color="primary"
											small
											@click="openDialogDriver()"
										>
											<v-icon>mdi-plus</v-icon>{{"Agregar"}}
										</v-btn>
									</v-card-title>
									<v-card-text>
										<v-data-table
											:headers="headersDriver"
											:items="itemsDriver"
											dense
											hide-default-footer
										>

											<template v-slot:item.RdrDriverName="{item}">
												{{item.RdrLastName + ' ' + item.RdrName}}
											</template>

											<template v-slot:item.action="{item}">
												<v-btn
													color="error"
													x-small
													@click="deleteDriver(item)"
												>
													<v-icon x-small>mdi-delete</v-icon>
												</v-btn>
											</template>
										</v-data-table>
									</v-card-text>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.TypeTransport != 0">
								<v-col>
									<s-date
										label="Fecha Traslado"
										v-model="referralGuide.RegDateDeliveryTransport"
									></s-date>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card dense outlined style="margin-top : -15px" height="155">
						<v-card-title style="margin-top : -10px">
							<h5>Observaciones Generales</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col>
									<s-textarea
										label="Observaciones"
										v-model="referralGuide.RegObservation"
									></s-textarea>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<!-- datos de contenedor cuando es exportacion -->
			<v-row v-if="referralGuide.TypeTransferReason == 12">
				<v-col>
					<v-card dense outlined>
						<v-card-title>
							<h5>Datos de contenedor</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col class="s-col-form">
									<s-select-definition
										label="Número de Contenedor 1"
										:def="1414"
										v-model="referralGuide.RegTypeContainer1"
									></s-select-definition>
								</v-col>
								<v-col class="s-col-form" v-if="referralGuide.RegTypeContainer1 != 3">
									<s-text
										label="Contenedor 1"
										v-model="referralGuide.RegNumberContainer1"
									>
									</s-text>
								</v-col>
								<v-col class="s-col-form" v-if="referralGuide.RegTypeContainer1 != 3">
									<s-text
										label="Presinto - Contenedor 1"
										v-model="referralGuide.RegSealContainer1"
									>
									</s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="s-col-form" v-if="referralGuide.RegTypeContainer1 != 3">
									<s-select-definition
										label="Número de Contenedor 2"
										:def="1414"
										v-model="referralGuide.RegTypeContainer2"
									></s-select-definition>
								</v-col>
								<v-col class="s-col-form" v-if="referralGuide.RegTypeContainer1 != 3">
									<s-text
										label="Contenedor 2"
										v-model="referralGuide.RegNumberContainer2"
									>
									</s-text>
								</v-col>
								<v-col class="s-col-form" v-if="referralGuide.RegTypeContainer1 != 3">
									<s-text
										label="Presinto - Contenedor 2"
										v-model="referralGuide.RegSealContainer2"
									>
									</s-text>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.RegTypeContainer1 == 3">
								<v-col class="s-col-form">
									<s-text
										label="Número de bultos o pallets"
										v-model="referralGuide.RegNumberLumpsPallet"
										number
										:min="1"
									>
									</s-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<!-- motivo de traslado y razon de traslado -->
			<v-row>
				<v-col>
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title style="margin-top : -10px">
							<h5>Motivo Traslado</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col
									v-for="item in itemsReasonTransfer"
									:key="item.DedValue"
									cols="4"
								>
									<v-radio-group
										v-model="referralGuide.TypeTransferReason"
										column
										hide-details
										style="margin-top : -30px"
									>
										<v-radio
											:label="item.DedDescription"
											color="red"
											:value="item.DedValue"
											hide-details
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row v-if="referralGuide.TypeTransferReason == 13" style="margin-top : -20px">
								<v-col>
									<s-textarea
										label="Motivo"
										v-model="referralGuide.RegOtherMotive"
									></s-textarea>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<v-card dense outlined style="margin-top : -15px">
						<v-card-title>
							<h5>Documentos Relacionados</h5>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col>
									<s-select-definition
										label="Tipo Documento"
										:def="1410"
										v-model="objTypeDocumentRelated"
										return-object
									></s-select-definition>
								</v-col>
								<v-col>
									<s-text
										label="N° Documento"
										v-model="itemRelatedDocument.RdoNumberDocument"
									>

									</s-text>
								</v-col>
								<v-col cols="1">
									<v-btn
										color="success"
										fab
										small
										@click="addItemRelatedDocument()"
									>
										<v-icon> mdi-plus </v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-data-table
										:headers="headersRelatedDocuments"
										:items="itemsRelatedDocuments"
										dense
									>
										<template v-slot:item.action="{ item }">
											<v-btn
												color="error"
												x-small
												@click="deleteItemRelatedDocument(item)"
											>
												<v-icon x-small>fas fa-trash-alt</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>

		<v-dialog
			v-if="dialogEditItemDetail"
			v-model="dialogEditItemDetail"
			width="400"
		>
			<v-card>
				<v-card-title>
					<span class="text-h5">{{"Editar"}}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-textarea
									label="Descripción"
									v-model="itemDetailEdit.RgdDescription"
									readonly
								></s-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<s-text
									label="Cantidad"
									number
									:min="0"
									v-model="itemDetailEdit.RgdQuantity"
								>
								</s-text>
							</v-col>
							<v-col>
								<s-text
									label="Total"
									number
									:min="0"
									v-model="itemDetailEdit.RgdTotal"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="updateItemItemDetail()"
						>
							Guardar
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogEditItemDetail = false"
						>
							Cancelar
						</v-btn>
					</v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog vehiculo -->
		<v-dialog
			v-model="dialogVehicle"
			v-if="dialogVehicle"
			width="400"
		>
			<v-card dense>
				<v-card-title>
					<span class="text-h5">{{"Vehiculos"}}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-text
									label="Placa"
									v-model="itemVehicle.RgvPlate"
									class="s-col-form"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="addVehicle()"
						>
							Agregar
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogVehicle = false"
						>
							Cancelar
						</v-btn>
					</v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog conductor -->
		<v-dialog
			v-model="dialogDriver"
			v-if="dialogDriver"
			width="400"
		>
			<v-card dense>
				<v-card-title>
					<span class="text-h5">{{"Conductores"}}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col class="s-col-form">
								<s-text
									label="Licencia"
									v-model="itemDriver.RdrLicense"
									class="s-col-form"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form">
								<s-select-definition
									label="Tipo Documento"
									:def="1036"
									v-model="itemDriver.TypeDocument"
								></s-select-definition>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<s-text
									label="N° Documento"
									v-model="itemDriver.RdrDocumentNumber"
									class="s-col-form"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<s-text
									label="Apellidos"
									v-model="itemDriver.RdrLastName"
									class="s-col-form"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<s-text
									label="Nombres"
									v-model="itemDriver.RdrName"
									class="s-col-form"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="addDriver()"
						>
							Agregar
						</v-btn>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogDriver = false"
						>
							Cancelar
						</v-btn>
					</v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
	import SArticle from "@/components/Utils/Logistics/SArticleSearch.vue";
	import _sSunarp from "@/services/General/SearchSunarp.js"
	import _sCompany from "@/services/General/GenCompanyService.js"
	import _sParameter from "@/services/General/ParameterService.js";
	import _sReferralGuide from "@/services/Logistics/LgsReferralGuideService.js"
	

	export default {
		components: {
			SArticle,
		},

		data() {
			return {
				groupGuide: 0,
				dialogVehicle : false,
				headersVehicle : [
					{text: 'Tipo', value : "RgvTypeVehicleName"},
					{text: 'Placa', value : "RgvPlate"},
					{text: 'Acción', value : "action"},
				],
				itemsVehicle : [],
				itemsVehicleDelete : [],
				itemVehicle : {},
				dialogDriver : false,
				headersDriver : [
					{text: 'Tipo', value : "RdrTypeDriverName"},
					{text: 'Licencia', value : "RdrLicense"},
					{text: 'N° Doc.', value : "RdrDocumentNumber"},
					{text: 'Conductor', value : "RdrDriverName"},
					{text: 'Acción', value : "action"},
				],
				itemsDriver : [],
				itemsDriverDelete : [],
				itemDriver : {},
				objTypeDocumentRelated : {},
				headersRelatedDocuments: [
					{text: "Tipo Documento", value : "TypeDocumentName"},
					{text: "N° Documento", value : "RdoNumberDocument"},
					{text: "Acción", value : "action"},
				],
				itemsRelatedDocuments : [],
				itemRelatedDocument : {},
				dialogEditItemDetail : false,
				itemsReasonTransfer: [],
				referralGuide : {
					TypeDetail : 1,
					TypeStartPoint : 1,
					RegAddressStartPoint : '',
					TypeTransport : 0
				},
				referralGuideItem : {},
				referralGuideItems : [],
				isReasonOther : false,
				isTypeDetailArticle : true,
				isTypeDetailService : false,
				headerDetailArticle : [
					{text: "Descripción", value : "RgdDescription"},
					{text: "Unidad", value : "RgdUnitMeasurement"},
					{text: "Cantidad", value : "RgdQuantity"},
					{text: "Total", value : "RgdTotal"},
					{text: "Acción", value : "action"},
				],
				referralGuideItems : [],
				itemsTypeDetail : [
					{ value: 1, text: 'Articulo' },
					{ value: 2, text: 'Servicio' },
				],
				supplierDestination : {},
				itemsStartPoint : [],
				itemsTypeTransport : [],
				objUnitMeasurement : {},
				objUnitMeasurementCargo : {},
				referralGuideItemsDelete : [],
				referralGuideItemsRelatedDocumentsDelete : [],
				itemDetailEdit :{},
				objSerie : {},
			}
		},

		watch: {
			"referralGuide.TypeReasonTransfer"(newValue, oldValue) {
				newValue == 13 ? this.isReasonOther = true : this.isReasonOther = false;
			},

			"referralGuide.TypeDetail"(newValue, oldValue)
			{
				newValue == 1 ? this.isTypeDetailArticle = true : this.isTypeDetailService = false;
			},

			"referralGuide.TypeArrivalPoint"(newValue, oldValue)
			{
				if(newValue != 1)
				{
					this.referralGuide.RegAddressStartPoint = '';
				}
				else
				{
					this.referralGuide.RegAddressStartPoint = this.supplierDestination.SunAddress;
				}
				
				console.log('newValue, oldValue', newValue, oldValue);
			}
		},

		methods: {
			openDialogDriver()
			{
				this.dialogDriver = true;
			},

			addDriver()
			{
				if(this.itemDriver.RdrLicense == "")
				{
					this.$fun.alert("Agregar licencia", "warning");
					return
				}
				
				this.itemDriver.RdrTypeDriver = 2;
				this.itemDriver.RdrTypeDriverName = 'Secundario'
				this.itemDriver.RdrID = 0;
				this.itemDriver.SecStatus = 1;
				this.itemDriver.UsrCreateID = this.$fun.getUserID();
				this.itemsDriver.push(this.itemDriver);
				this.itemDriver = {};
				this.dialogDriver = false;
			},

			deleteDriver(item)
			{
				if(item.RdrID != 0)
				{
					item.SecStatus = 0;
					item.UsrUpdateID = this.$fun.getUserID();
					this.itemsDriverDelete.push(item);
				}
				this.itemsDriver = this.itemsDriver.filter(element => {
					return element.RdrLicense != item.RdrLicense
				});
			},

			openDialogVehicle()
			{
				this.dialogVehicle = true;
			},

			addVehicle()
			{
				if(this.itemVehicle.RgvPlate == "")
				{
					this.$fun.alert("Agregar placa", "warning");
					return
				}
				this.itemVehicle.RgvTypeVehicle = 2;
				this.itemVehicle.RgvTypeVehicleName = 'Secundario';
				this.itemVehicle.RgvID = 0;
				this.itemVehicle.SecStatus = 1;
				this.itemVehicle.UsrCreateID = this.$fun.getUserID();
				this.itemsVehicle.push(this.itemVehicle);
				this.itemVehicle = {};
				this.dialogVehicle = false;
			},

			deleteVehicle(item)
			{
				if(item.RgvID != 0)
				{
					item.SecStatus = 0;
					item.UsrUpdateID = this.$fun.getUserID();
					this.itemsVehicleDelete.push(item);
				}
				this.itemsVehicle = this.itemsVehicle.filter(element => {
					return element.RgvPlate != item.RgvPlate
				});
			},

			addItemRelatedDocument()
			{
				if(this.objTypeDocumentRelated.DedValue == undefined)
				{
					this.$fun.alert("Ingrese tipo de documento", "warning");
					return;
				}
				if(this.itemRelatedDocument.RdoNumberDocument == "")
				{
					this.$fun.alert("Ingrese número de documento", "warning");
					return;
				}
				this.itemRelatedDocument.RdoID = 0;
				this.itemRelatedDocument.TypeDocumentName = this.objTypeDocumentRelated.DedDescription;
				this.itemRelatedDocument.TypeDocument = this.objTypeDocumentRelated.DedValue;
				this.itemRelatedDocument.RdoLine = parseInt(this.itemsRelatedDocuments.length) + 1;
				this.itemRelatedDocument.SecStatus = 1;
				this.itemRelatedDocument.UsrCreateID = this.$fun.getUserID();
				this.itemRelatedDocument.UsrUpdateID = this.$fun.getUserID();
				this.itemsRelatedDocuments.push(this.itemRelatedDocument);
				this.itemRelatedDocument = {};
			},

			editItemDetail(item)
			{
				this.itemDetailEdit = item;
				this.dialogEditItemDetail = true;
			},

			updateItemItemDetail(item)
			{
				this.referralGuideItems.forEach(element => {
					if(element.RgdLine == this.itemDetailEdit)
					{
						element.RgdDescription = this.itemDetailEdit.RgdDescription;
						element.RgdQuantity = this.itemDetailEdit.RgdQuantity;
					}
				})
				this.itemDetailEdit = {};
				this.dialogEditItemDetail = false;
			},

			deleteItemRelatedDocument(item)
			{
				this.itemsRelatedDocuments = this.itemsRelatedDocuments.filter(e =>{
					return e.RdoLine != item.RdoLine;
				})
				if(item.RdoID > 0)
				{
					item.SecStatus = 0;
					item.UsrUpdateID = this.$fun.getUserID();
					this.referralGuideItemsRelatedDocumentsDelete.push(item);
				}
				

			},

			deleteDetail(item)
			{
				this.referralGuideItems = this.referralGuideItems.filter(e =>{
					return e.RgdLine != item.RgdLine;
				})
				if(item.RgdID > 0)
				{
					item.SecStatus = 0;
					item.UsrUpdateID = this.$fun.getUserID();
					this.referralGuideItemsDelete.push(item);
				}
				

			},

			getDataReasonTransfer(items) {
				this.itemsReasonTransfer = items;
			},

			validationData()
			{
				let errors = {
					error : 0,
					message : 0,
					ref : ''
				};

				if(this.referralGuide.TypeDocumentDestination == 1 && this.referralGuide.RegDocumentNumberDestination.length > 8)
				{
					errors.error = 1;
					errors.message = "Número de documento no valido para tipo de documento";
					return errors;
				}

				if(this.referralGuide.RegDocumentNumberDestination == "")
				{
					errors.error = 1;
					errors.message = "Ingrese N° Doc destinatario";
					return errors;
				};

				if(this.referralGuide.TypeUnitMeasurement == null)
				{
					errors.error = 1;
					errors.message = "Ingrese unidad de medida de la carga";
					return errors;
				}

				if(this.referralGuide.RegGrossWeightCargo == "")
				{
					errors.error = 1;
					errors.message = "Ingrese peso bruto de la carga";
					return errors;
				}

				if(this.referralGuide.RegAddressStartPoint == "")
				{
					errors.error = 1;
					errors.message = "Ingrese dirección de partida";
					return errors;
				}

				if(this.referralGuide.RegAddressArrivalPoint == "")
				{
					errors.error = 1;
					errors.message = "Ingrese dirección de llegada";
					return errors;
				}

				if(this.referralGuide.TypeTransport == "" || this.referralGuide.TypeTransport == 0 || this.referralGuide.TypeTransport == undefined)
				{
					errors.error = 1;
					errors.message = "Ingrese tipo de transporte";
					return errors;
				}

				if(this.referralGuide.TypeTransport == 1)
				{
					if(this.referralGuide.RegRucTransport == "")
					{
						errors.error = 1;
						errors.message = "Ingrese número de documento de transporte";
						return errors;
					}
					if(this.referralGuide.RegBusinessNameTransport == "")
					{
						errors.error = 1;
						errors.message = "Ingrese denominación/apellidos y nombres de transporte";
						return errors;
					}
				}

				if(this.referralGuide.TypeTransferReason == "" || this.referralGuide.TypeTransferReason == undefined)
				{
					errors.error = 1;
					errors.message = "Ingrese motivo de traslado";
					return errors;
				}

				if(this.referralGuide.TypeTransferReason == 13)
				{
					if(this.referralGuide.RegOtherMotive == "" || this.referralGuide.RegOtherMotive == undefined)
					{
						errors.error = 1;
						errors.message = "Ingrese otro motivo de traslado";
						return errors;
					}
				}

				if(this.referralGuide.TypeTransferReason == 12)
				{
					if(this.referralGuide.RegTypeContainer1 == 1)
					{
						if(this.referralGuide.RegNumberContainer1 == "" || this.referralGuide.RegSealContainer1 == "")
						{
							errors.error = 1;
							errors.message = "Ingrese numero de contenedor1 o precinto1";
							return errors;
						}
					}
					if(this.referralGuide.RegTypeContainer1 == 3 && this.referralGuide.RegNumberLumpsPallet <= 0)
					{
						errors.error = 1;
						errors.message = "Ingrese numbero de bultos o pallets";
						return errors;
					}
				}

				if(this.objSerie.DedID == undefined)
				{
					errors.error = 1;
					errors.message = "Ingrese serie";
					return errors;
				}

				/* if(this.itemsVehicle.length == 0 && this.referralGuide.TypeTransport == 2)
				{
					errors.error = 1;
					errors.message = "Ingrese datos de vehículo";
					return errors;
				} */

				/* if(this.itemsDriver.length == 0 && this.referralGuide.TypeTransport == 2)
				{
					errors.error = 1;
					errors.message = "Ingrese datos de conductor";
					return errors;
				} */
				
				return errors;
			},

			save()
			{
				var errors = this.validationData();
				if(errors.error > 0)
				{
					this.$fun.alert(errors.message, "warning");
					return;
				}
				if(this.referralGuideItems.length <= 0)
				{
					this.$fun.alert("Ingrese detalles", "warning");
					return;
				}
				// this.referralGuide.RegUnitMeasurementCargo = this.objUnitMeasurementCargo.DedAbbreviation;
				this.referralGuide.RegSerie = this.objSerie.DedDescription;
				this.referralGuide.RgdUnitMeasurementCargo = this.objUnitMeasurementCargo.DedAbbreviation;
				this.referralGuide.RegBusinessNameDestination = this.supplierDestination.SunName;
				this.referralGuide.RegStartPointAddressDestination = this.supplierDestination.SunAddress;
				this.referralGuide.SecStatus = 1;
				this.referralGuide.UsrCreateID = this.$fun.getUserID();
				this.referralGuide.UsrUpdateID = this.$fun.getUserID();
				let detalles = this.referralGuideItems;
				detalles.push(...this.referralGuideItemsDelete);
				let relatedDocuments = this.itemsRelatedDocuments;
				relatedDocuments.push(...this.referralGuideItemsRelatedDocumentsDelete);
				let vehicles = this.itemsVehicle;
				vehicles.push(...this.itemsVehicleDelete);
				let drivers = this.itemsDriver;
				drivers.push(...this.itemsDriverDelete);
				this.referralGuide.LgsReferralGuideDetails = detalles;
				this.referralGuide.LgsReferralGuideRelatedDocuments = relatedDocuments;
				this.referralGuide.LgsReferralGuideVehicles = vehicles;
				this.referralGuide.LgsReferralGuideDrivers = drivers;
				this.referralGuide.TypeAreGuide = 1;
				this.referralGuide.TypeGuide = 1;//this.typeGuide;
				console.log('guardar' , this.referralGuide);
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						_sReferralGuide
						.save(this.referralGuide, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guía generada correctamente", "success");
								this.$emit("refreshTable")
								return;
							}
						});
					}
				})
				
			},

			closeDialogReferral()
			{
				this.$emit("closeDialogReferral");
			},

			inputArticle(item)
			{
				console.log('itemarticle', item);
			},

			addReferralGuideItems()
			{
				if(this.referralGuideItem.TypeDetail == 1)
				{
					this.referralGuideItem.RgdDescription = this.referralGuideItem.RgdDescription.ArtName;
				}
				this.referralGuideItem.RgdUnitMeasurement = this.objUnitMeasurement.DedAbbreviation;
				this.referralGuideItem.TypeUnitMeasurement = this.objUnitMeasurement.DedValue;

				if(this.referralGuideItem.RgdUnitMeasurement == "" || this.referralGuideItem.RgdUnitMeasurement == null)
				{
					this.$fun.alert("Ingrese Unidad de medida");
					return;
				}

				if(this.referralGuideItem.RgdQuantity <= 0)
				{
					this.$fun.alert("Ingrese Cantidad");
					return;
				}

				if(this.referralGuideItem.RgdTotal <= 0)
				{
					this.$fun.alert("Ingrese Total");
					return;
				}
				this.referralGuideItem.SecStatus = 1;
				this.referralGuideItem.UsrCreateID = this.$fun.getUserID();
				this.referralGuideItem.UsrUpdateID = this.$fun.getUserID();
				this.referralGuideItem.RgdLine = parseInt(this.referralGuideItems.length) + 1;
				this.referralGuideItems.push(this.referralGuideItem);
				this.referralGuideItem = {};
			},

			searchSunat(documentNumber, typesearch)
			{
				if(typesearch == 1)
				{
					if(documentNumber != "")
					{
						_sSunarp
						.Search(documentNumber, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.supplierDestination = resp.data;
								this.referralGuide.RegAddressStartPoint = resp.data.SunAddress;
							}
						})
					}
				}
				
				if(typesearch == 2)
				{
					if(documentNumber != "")
					{
						_sSunarp
						.Search(documentNumber, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								console.log('repsyesta ', resp.data);
								this.referralGuide.RegNamesDriver = resp.data.SunNames;
								this.referralGuide.RegLastNameDriver = resp.data.SunFatherName + ' ' + resp.data.SunMotherName;
							}
						})
					}
				}

				if(typesearch == 3)
				{
					if(this.referralGuide.RegRucTransport != "")
					{
						_sSunarp
						.Search(documentNumber, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.referralGuide.RegBusinessNameTransport = resp.data.SunName;
							}
						})
					}
				}
			},

			getCompany()
			{
				let a = {
						PrmName: "SunshineRuc"
					};
						
					_sParameter
					.search(a, this.$fun.getUserID())
					.then((r) => {
						var SunshineRuc = r.data.PrmValue;

						if(SunshineRuc != "")
						{
							_sCompany
							.searchbyruc(SunshineRuc, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
								}
							});
						}
					});
				
			},

			getDataTypeArrivalPoint(items)
			{
				this.itemsStartPoint = items;
			},

			getDataTypeTransport(items)
			{
				this.itemsTypeTransport = items;
			}

		},

		created () {
			this.getCompany();
			var areId = 0
			var areAlow = this.$fun.getSecurity().AreasAllow
			if (areAlow.length > 0) {
				var areId = areAlow[0].AreID;
			}
			if (areId == 7) {
				this.groupGuide = 67
			}
			if (areId == 9) {
				this.groupGuide = 68
			}
			if (areId == 73) {
				this.groupGuide = 66
			}

			console.log("this.groupGuide", this.groupGuide)
		},
		mounted(){
			
		}
	}
</script>
